























































import Vue from 'vue';
import Component from 'vue-class-component';

import { namespace } from 'vuex-class';
import { LoginResult } from '../api/auth/login-result.class';
import Toast from '../shared/types/toast.class';
import { Login } from '../api/auth/login.class';
import { ToastType } from '../shared/types/toast-type.enum';

const authModule = namespace('auth');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class PasswordReset extends Vue {
  email = '';

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @authModule.Action('requestResetPassword')
  requestResetPassword!: (email: string) => Promise<void>;

  async submitLogin() {
    this.addLoader('passwordreset');
    const toast = new Toast('Password reset e-mail sent!', ToastType.SUCCESS);
    try {
      await this.requestResetPassword(this.email);
    } catch (e) {
      toast.message = 'Could not request password reset';
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('passwordreset');
  }
}
